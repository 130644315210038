var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data.properties.filed_content != 'Hide')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],style:(_vm.getElementStyle)},[(
      !_vm.data.properties.hideLabel || _vm.data.properties.filed_content != 'Hide'
    )?_c('el-row',{attrs:{"align":"middle"}},[_c('el-col',{style:(_vm.computedStyles),attrs:{"span":_vm.showLabel && !_vm.isDefalutPosq ? 4 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel && !_vm.data.properties.hideLabel)?_c('label',{staticStyle:{"margin-right":"5px"},attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.label || _vm.data.label))]):_vm._e(),(_vm.data.validations.required)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)]),(_vm.data.integration_settings.allow_integration_quick_add)?_c('el-col',{attrs:{"span":4}},[_c('quickIntegrationAddWidget',{attrs:{"parentForm":_vm.form,"parentFields":_vm.fieldsData,"parentData":_vm.data}})],1):_vm._e(),_c('el-col',{attrs:{"span":20}},[(!_vm.isView)?_c('div',[_c('el-select',{style:(_vm.getStyle),attrs:{"placeholder":_vm.data.placeholder,"suffix-icon":_vm.data.properties.el_icon,"disabled":_vm.checkReadonly(),"clearable":"","filterable":"","remote":"","remote-method":_vm.searchOptions},on:{"change":_vm.handleSelectedValue,"clear":function($event){return _vm.handleClearIntegration()}},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},[(
              _vm.data.is_entity_field &&
              _vm.form[_vm.data.key + '/name'] &&
              _vm.form[_vm.data.key] &&
              !_vm.optionRefresh
            )?_c('el-option',{attrs:{"label":_vm.form[_vm.data.key + '/name'],"value":_vm.form[_vm.data.key]}}):_vm._e(),_vm._l((_vm.options),function(option,index){return _c('el-option',{key:index,attrs:{"label":option[_vm.selectedColumnLabel],"value":option[_vm.selectedColumnKey]}})}),(_vm.hasMore && this.options.length >= 100)?_c('div',{staticStyle:{"text-align":"center"}},[_c('el-button',{attrs:{"type":"text","loading":_vm.localIsLoading},on:{"click":_vm.handleScroll}},[_vm._v(" Load More... ")])],1):_vm._e()],2),(_vm.isActive || _vm.isDataTableField)?_c('span',{staticClass:"setting-icon",on:{"click":_vm.openSettings}},[_c('i',{staticClass:"el-icon-s-tools"})]):_vm._e()],1):_c('div',[_vm._v(" "+_vm._s(_vm.form[_vm.selectedValue + "/name"] || _vm.form[_vm.selectedValue])+" ")])])],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }